/* eslint-disable array-callback-return */
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import { useCookiesLogin } from "../../config/cookies";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";

import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";

import Header from "../../components/Header";
import PixQRCode from "./qrcode";
import Resume from "./resume";

const RedCoins = () => {
  const { cookies } = useCookiesLogin();
  console.log(cookies, "kiiii")
  const [isLoading, setIsLoading] = useState(false);

  const { id, bussines_id, name, email } = cookies.BRAUM_TOKEN;
  const [tokenSerasa, setTokenSerasa] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [coinsPrice, setCoinsPrice] = useState(35);
  const [resume, setResume] = useState(false);
  const initialValues = {
    quantity: "",
  };

  const handleCardClick = (quantity) => {
    const newTotal = quantity * 35;
    setTotalPrice(newTotal);
    console.log(newTotal,"newTotal")
    setResume(true);
  };

  const valuesSchema = yup.object().shape({
    quantity: yup
      .number()
      .min(10, "A quantidade mínima é 10")
      .required("Quantidade de fichas é obrigatório"),
  });

  useEffect(() => {
    async function getToken() {
      const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
        method: "GET",
      });
      const returnToken = await authSerasa.json();
      setTokenSerasa(returnToken["token"]);
    }
    getToken();
  }, []);

  useEffect(() => {

  }, [resume])

  return (
    <Box p={5}>
      <Box mt={10}>
        <ToastContainer />

        {resume === false ? (<>
          <Header
            title={`SOLICITAR FICHAS`}
            subtitle="Solicite fichas para realizar análise de crédito"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={valuesSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setIsLoading(true);
              setSubmitting(true);

              if (values.quantity <= 0) {
                toast.info("A quantidade de fichas não pode ser 0", {
                  theme: "dark",
                });
                setIsLoading(false);
                return;
              }

              // Prepare request payload

              const requestBody = {
                client_id: id,
                business: bussines_id,
                status_payment: "PENDING",
                quantity: values.quantity,
                total_value: totalPrice,
              };

              try {
                // Make the POST request
                const response = await fetch('https://appsup4tech.com:8000/reduto/request/coins', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestBody),
                });

                const data = await response.json();

                if (response.ok) {
                  toast.success("Fichas solicitadas com sucesso!", {
                    theme: "dark",
                  });
                } else {
                  toast.error("Erro ao solicitar fichas. Tente novamente.", {
                    theme: "dark",
                  });
                }
              } catch (error) {
                toast.error("Erro de rede ao solicitar fichas.", {
                  theme: "dark",
                });
              } finally {
                setIsLoading(false);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => {
              const totalPrice = values.quantity * coinsPrice || 0;

              const cardData = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

              return (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  >
                    {cardData.map((quantity) => (
                      <Box
                        key={quantity}
                        sx={{
                          padding: '20px',
                          backgroundColor: '#181818',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          {quantity} consultas
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{
                          fontSize: 30,
                          color: "#83d84d"
                        }}>
                          R${(quantity * 35).toFixed(2)}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleCardClick(quantity)}
                        >
                          Solicitar
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </form>
              );
            }}
          </Formik>
        
        </>
        
        ) : (
            <>
              <Header
                title={`Falta Pouco !`}
                subtitle="Pagamento: PIX"
              />
              
              <Resume
                email={email}
                nome={name}
                quantidade={totalPrice / 35}
                valor={totalPrice}
              />

              <div>
                <Box mt={10} sx={{
                  backgroundColor: '',
                  boxShadow: '0px 4px 6px #f5e58a',
                  padding: '20px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Box
                    sx={{
                      padding: '20px',
                      backgroundColor: '',
                      borderRadius: '8px',
                      boxShadow: 'inset 0px 4px 6px rgba(0, 0, 0, 0.05)',
                      textAlign: 'left',
                      width: '100%',  // Garante que ele ocupe a largura total disponível dentro de 400px
                    }}
                  >
                    <Typography variant="h3" gutterBottom sx={{
                      color: "#ffeb80"
                    }}>
                      Chaves PIX
                    </Typography>
                    <PixQRCode value={totalPrice} extrato={`${name} - ${email}`}></PixQRCode>
                    <Box sx={{
                      marginTop: 5
                    }}>
                      <Typography variant="body1" gutterBottom sx={{
                        fontSize: 18,
                        color: "#fff"
                      }}>
                        Chave email: financeiro@redutocapital.com.br
                      </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom sx={{
                      fontSize: 16,
                      color: "#dcff25"
                    }}>
                      Após análise de solicitação e pagamento enviaremos após 3 dias as fichas liberadas no seu acesso.
                    </Typography>

                  </Box>

                </Box>
                {/* <Typography variant="body1" gutterBottom sx={{
                  fontSize: 22,
                  color: "#fff"
                }}>
                  QRCODE 
                </Typography> */}

                
              </div>
          </>
        )}
        

        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default RedCoins;
