import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";
import LogoReduto from "./../../assets/logo.png";

export function Logo() {
  return (
    <img src={LogoReduto} alt="react logo" style={{ width: 150 }} />
  );
}

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Home");
  const { cookies } = useCookiesLogin();

  const title = {
    admin: "Administrador",
    consultant: "Revenda",
    user: "Usuário",
  };

  const response = cookies.BRAUM_TOKEN || {};
  const titleFormat = title[response.role] || "";

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#83d84d !important",
        },
        "& .pro-menu-item.active": {
          color: "#83d84d !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Logo />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {response.name}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {titleFormat}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Home"
              to=""
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            

            <SubMenu title="RED SCORE" icon={<CardTravelOutlinedIcon />}>
              <Item
                title="Consultar"
                to="redscore"
                icon={<AssignmentIndOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Recarregar"
                to="redcoins"
                icon={<AssignmentIndOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />
            </SubMenu>
            

            <SubMenu title="Simulações" icon={<CardTravelOutlinedIcon />}>
              <Item
                title="RED 25 - Mensal"
                to="red25"
                icon={<RequestPageOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="RED 25 - Trimestral"
                to="red25tri"
                icon={<RequestPageOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="RED 25 - Semestral"
                to="red25sem"
                icon={<RequestPageOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="RED RURAL"
                to="redrural"
                icon={<RequestPageOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
            </SubMenu>

            <SubMenu title="Cadastros" icon={<PersonAddIcon />}>
              <Item
                title="Cadastro Empresa"
                to="form"
                icon={<CardTravelOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="Empresas"
                to="business"
                icon={<CardTravelOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="Cadastro Cliente"
                to="createUser"
                icon={<PersonAddIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
              <Item
                title="Clientes"
                to="clients"
                icon={<PersonAddIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
            </SubMenu>

            <SubMenu title="Propostas" icon={<CardTravelOutlinedIcon />}>
              <Item
                title="Propostas"
                to="proposals"
                icon={<CardTravelOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                style={{ margin: "8px 0" }}
              />
            </SubMenu>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default SidebarAdmin;
