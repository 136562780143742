import { Box, Typography } from '@mui/material';
import React from 'react';

const Resume = ({ nome, email, revenda, quantidade, valor, data }) => {
    const dataAtual = new Date();
    const dataFormatadaBR = dataAtual.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    });
    return (
        <Box
            sx={{
                backgroundColor: '',
                boxShadow: '0px 4px 6px #f5e58a',
                padding: '20px',
                borderRadius: '8px',  
                display: 'flex',  
                justifyContent: 'center', 
                alignItems: 'center', 
            }}
        >
            <Box
                sx={{
                    padding: '20px',
                    backgroundColor: '',
                    borderRadius: '8px',
                    boxShadow: 'inset 0px 4px 6px rgba(0, 0, 0, 0.05)',
                    textAlign: 'left',
                    width: '100%',  // Garante que ele ocupe a largura total disponível dentro de 400px
                }}
            >
                <Typography variant="h3" gutterBottom sx={{ 
                    color: "#ffeb80"
                }}>
                    Comprovante de Compra
                </Typography>

                <Typography variant="body1" mb={1} sx={{ fontSize: 19 }}><strong>Nome:</strong> {nome}</Typography>
                <Typography variant="body1" mb={1} sx={{ fontSize: 19 }}><strong>Email:</strong> {email}</Typography>
                <Typography variant="body1" mb={1} sx={{ fontSize: 19 }}><strong>Quantidade:</strong> {quantidade}</Typography>
                <Typography variant="body1" mb={1} sx={{ fontSize: 19 }}><strong>Valor:</strong> R$ {valor}</Typography>
                <Typography variant="body1" mb={1} sx={{ fontSize: 19 }}><strong>Data:</strong> {dataFormatadaBR}</Typography>
                {/* <Typography variant="body1 mb={3}"><strong>Revenda:</strong> {revenda}</Typography> */}
            </Box>
        </Box>
    );
};

export default Resume;
